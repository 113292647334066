import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
 
//子路由
const childRoutes: Array<RouteRecordRaw> = [
  
  //企业管理
  {
    path: '/auditView',
    name: 'auditView',
    component: () => import('../views/module/enterpriseAudit/auditView.vue')//企业审核
  },
  //用户管理
  {
    path: '/accountList',
    name: 'accountList',
    component: () => import('../views/module/account/accountList.vue')//用户列表
  },
  //设备管理
  {
    path: '/deviceList',
    name: 'deviceList',
    component: () => import('../views/module/device/deviceList.vue')//设备列表
  },
  //充电桩设备列表
  {
    path: '/chargingList',
    name: 'chargingList',
    component: () => import('../views/module/chargingDevice/chargingList.vue')//充电桩设备列表
  },
  //充电桩订单
  {
    path: '/chargingElectricList',
    name: 'chargingElectricList',
    component: () => import('../views/module/chargingDevice/electric/chargingElectricList.vue')//充电桩订单
  },  
  //提现申请
  {
    path: '/rechargeCashList',
    name: 'rechargeCashList',
    component: () => import('../views/module/chargingDevice/electric/rechargeCashList.vue')//提现申请
  }, 
  //充电桩订单统计
  {
    path: '/chargingStatistics',
    name: 'chargingStatistics',
    component: () => import('../views/module/chargingDevice/statistics/chargingStatistics.vue')//充电桩订单统计
  },
  //部署地点
  {
    path: '/deployList',
    name: 'deployList',
    component: () => import('../views/module/deploy/deployList.vue')//部署地点
  },
 
  {
    path: '/deviceControlList',
    name: 'deviceControlList',
    component: () => import('../views/module/device/controll/deviceControlList.vue')//设备控制表
  },
  {
    path: '/deviceModelList',
    name: 'deviceModelList',
    component: () => import('../views/module/device/model/deviceModelList.vue')//设备型号表
  },
  {
    path: '/deviceStateList',
    name: 'deviceStateList',
    component: () => import('../views/module/device/state/deviceStateList.vue')//设备状态表
  },

  //开发管理
  {
    path: '/commissionList',
    name: 'commissionList',
    component: () => import('../views/module/commission/commissionList.vue')//代办列表
  },
  {
    path: '/bugList',
    name: 'bugList',
    component: () => import('../views/module/bug/bugList.vue')//bug列表
  },
  {
    path: '/homeCarouselList',
    name: 'homeCarouselList',
    component: () => import('../views/module/homeCarousel/homeCarouselList.vue')//首页轮播图
  },
  {
    path: '/informList',
    name: 'informList',
    component: () => import('../views/module/inform/informList.vue')//消息通知列表
  },
  {
    path: '/billingAuditView',
    name: 'billingAuditView',
    component: () => import('../views/module/billingManagement/billingAuditView.vue')//开票审核
  },
  {
    path: '/billingRecordView',
    name: 'billingRecordView',
    component: () => import('../views/module/billingManagement/billingRecordView.vue')//开票记录
  },
  {
    path: '/flowList',
    name: 'flowList',
    component: () => import('../views/module/tariffManagement/flowList.vue')//流量记录
  },
  {
    path: '/mapIndex',
    name: 'mapIndex',
    component: () => import('../views/module/map/large-screen/indexList.vue')//地图
  },
  {
    path: '/roleManagerView',
    name: 'roleManagerView',
    component: () => import('../views/module/adminSetting/roleManagerView.vue') //管理员设置-角色管理
  },
  {
    path: '/adminList',
    name: 'adminList',
    component: () => import('../views/module/adminSetting/adminList.vue') //管理员设置-管理员列表
  },
  {
    path: '/promotionList',
    name: 'promotionList',
    component: () => import('../views/module/promotion/promotionList.vue') //推广管理-推广员列表
  },
  // //产品信息
  // {
  //   path: '/cert',
  //   name: 'cert',
  //   component: () => import('../views/module/cert/certList.vue')//产品信息
  // },
  // //信息模板
  // {
  //   path: '/certTemp',
  //   name: 'certTemp',
  //   component: () => import('../views/module/certTemp/certTempList.vue')//信息模板
  // },
   //申请标签
   {
    path: '/applyLabel',
    name: 'applyLabel',
    component: () => import('../views/module/labelManager/applyLabel.vue')//申请标签
  },
   //标签付款记录
  {
    path: '/labelPayRecord',
    name: 'labelPayRecord',
    component: () => import('../views/module/labelManager/labelPayRecord.vue')//标签付款记录
  },
   //信息审核
   {
    path: '/infoAudit',
    name: 'infoAudit',
    component: () => import('../views/module/verifyAudit/infoAudit.vue')//信息审核
  },
   //标签收款记录
   {
    path: '/labelReceivePayRecord',
    name: 'labelReceivePayRecord',
    component: () => import('../views/module/verifyAudit/labelReceivePayRecord.vue')//标签收款记录
  },
]

//主路由
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login/LoginView.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('../views/error/404View.vue')
  },
  {
    path: '/certView',
    name: 'certView',
    component: () => import('../views/certView.vue')
  }, 
  {
    path: '/navigation',
    name: 'Navigation',
    component: () => import('../views/NavigationView.vue'),
    redirect: "/auditView",
    children: childRoutes
  }

]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) =>{
  console.log("router.beforeEach===========================>")
  // 假设用户权限存储在Vuex store的state中
  // const canAccessAdminPage = store.state.user.isAdmin
  console.log(to)
  console.log(from)
  const canAccessAdminPage = false
 
  if (to.matched.some(record => record.meta.requiresAdmin)) {
    // 如果当前路由需要管理员权限，则检查用户是否有权限
    if (canAccessAdminPage) {
      // 如果有权限，继续
      next()
    } else {
      // 如果没有权限，跳转到登录页面或其他页面
      next({ path: '/login' })
    }
  } else {
    // 如果当前路由不需要特殊权限，直接通行
    next()
  }
})


export default router
